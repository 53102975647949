import React, { useState } from "react"
import { Collapse } from "react-bootstrap"
import registrazioneIcon from "../../assets/icons/registrazione-e-ico.png"
import profiloIcon from "../../assets/icons/profilo-e-ico.png"
import recensioniIcon from "../../assets/icons/recensioni-e-ico.png"
import carrieraIcon from "../../assets/icons/registrazione-e-ico.png"
import { Link } from "react-router-dom"
import faqHead from "../../assets/images/faq/faq_desktop.jpg"
import faqCss from "./faq.css?inline"

const FAQ: React.FC = () => {
  const [open, setOpen] = useState<any>({
    registrazione: true,
    profilo: true,
    recensioni: true,
    carriera: true,
  })

  return (
    <section className="faq-page" style={{ paddingTop: "0px" }}>
      <div className="container-fluid">
        <div className="row">
          <div
            className="faq-head"
            style={{ backgroundImage: `url(${faqHead})` }}
          >
            <div className="faq-head-title">
              <h1>FAQ.</h1>
              <h3>
                In questa sezione puoi trovare le risposte alle domande più
                frequenti
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col col-sm col-md col-lg-12 col-xl-12">
            <div className="page-content faqc">
              <div className="well-faq-new">
                <div className="faq-title">
                  <span
                    className="title-gifts-n"
                    onClick={() =>
                      setOpen({ ...open, registrazione: !open.registrazione })
                    }
                    aria-controls="item-524"
                    aria-expanded={open.registrazione}
                  >
                    <img src={registrazioneIcon} className="ico" />
                    Registrazione
                  </span>
                </div>
                <Collapse in={open.registrazione}>
                  <div id="item-524" className="content-gifts-n">
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          E' complesso inserire una recensione?
                        </span>
                      </strong>
                    </p>{" "}
                    <p> </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Lasciare una recensione su eShoppingAdvisor è
                        estremamente semplice e veloce, ti basteranno pochi
                        click per lasciare la tua opinione attribuendo un rating
                        ai servizi ricevuti e la possibilità di lasciare un
                        commento.
                      </span>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Come posso essere sicuro dell'autenticità delle
                          recensioni che leggo sul portale?
                        </span>
                      </strong>
                    </p>
                    <p> </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        eShoppingAdvisor consente a ogni utente registrato di
                        scrivere una recensione.
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Attraverso automatismi software e un team di persone
                        dedicate ogni recensione viene analizzata e validata
                        secondo le policy del portale.
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Le recensioni sospette possono essere comunque segnalate
                        al nostro team che provvederà a effettuare le verifiche
                        necessarie e nel caso a rimuovere immediatamente la
                        recensione.
                      </span>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Chi si può registrare come utente?
                        </span>
                      </strong>
                    </p>
                    <p> </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Può registrarsi come utente qualsiasi persona fisica in
                        maniera gratuita e per sempre.
                      </span>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Come mi registro?
                        </span>
                      </strong>
                    </p>
                    <p> </p>
                    <ol>
                      <li dir="ltr">
                        <span style={{ fontSize: "10pt" }}>
                          In alto a destra in <Link to="/">home page</Link>{" "}
                          trovi il link ”
                          <Link to="/it/registrazione/cliente">Registrati</Link>
                          ” attraverso cui accedere al modulo di registrazione;
                        </span>
                      </li>
                      <li dir="ltr">
                        <span style={{ fontSize: "10pt" }}>
                          Clicca sul logo di Facebook o Google per effettuare il
                          login dai tuoi social in pochi secondi;
                        </span>
                      </li>
                    </ol>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Non vuoi effettuare il login dai social
                      </span>
                      ?
                      <span style={{ fontSize: "10pt" }}>
                        &nbsp;Clicca sull’icona della mail e prosegui come
                        indicato:
                      </span>
                    </p>
                    <ol>
                      <li dir="ltr">
                        <span style={{ fontSize: "10pt" }}>
                          Inserisci i dati richiesti: nome, sesso, email,
                          password e conferma password;
                        </span>
                      </li>
                      <li dir="ltr">
                        <span style={{ fontSize: "10pt" }}>
                          Clicca sulla conferma di registrazione all’interno
                          della mail che ti invieremo e accedi direttamente alla
                          tua Area Riservata Utente;
                        </span>
                      </li>
                      <li dir="ltr">
                        <span style={{ fontSize: "10pt" }}>
                          Inserisci tutti i dati mancanti e completa la tua
                          vetrina personale.
                        </span>
                      </li>
                    </ol>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Ora puoi finalmente accedere alla tua Area Riservata e
                        iniziare a scrivere tante utili recensioni, guadagnando
                        coupon sconto per risparmiare sui tuoi prossimi acquisti
                        sugli e-commerce&nbsp;registrati su eShoppingAdvisor.
                      </span>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Perché registrarsi su eShoppingAdvisor?
                        </span>
                      </strong>
                    </p>
                    <p> </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Registrandoti su&nbsp;
                        <Link to="/">eShoppingAdvisor</Link> potrai scrivere
                        recensioni, risparmiare e condividere le tue esperienze
                        di acquisto online con tutta la community, aiutando gli
                        altri utenti a fare acquisti più sicuri e consapevoli;
                        tu stesso prima di fare un acquisto su internet, potrai
                        controllare la reputazione dell’e-commerce su cui
                        desideri acquistare e leggere le recensioni ricevute, in
                        modo da evitare potenziali truffe o disservizi.
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Ogni volta che scrivi una recensione su qualsiasi
                        negozio online guadagni punti grazie ai quali potrai
                        sbloccare fantastici coupon!
                      </span>
                    </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Per ogni Recensione Certificata maturerai 1 punto e per
                        ogni Recensione Verificata 0,25 punti. Ogni punto ti
                        servirà a sbloccare un coupon sconto. Nella sezione
                        Coupon, presente dentro la tua Area Riservata,
                        troverai&nbsp;il tuo saldo punti e la lista dei coupon
                        sconto sbloccati. Potrai inoltre scoprire nuovi numerosi
                        coupon ogni giorno e cercarli per e-commerce o per
                        categoria. Avrai a disposizione un Black Friday
                        permanente, attivo 365 giorni l'anno, 24 ore su
                        24.&nbsp;
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Quando rilascerai una recensione a seguito di un invito
                        da parte dell’e-commerce, questa sarà automaticamente
                        Certificata e quindi del valore di 1 punto. In caso
                        contrario, varrà 0.25 punti e sarà una Recensione
                        Verificata. Come certificarla? Carica una prova di
                        acquisto (fattura, ricevuta, conferma ordine), il nostro
                        la staff la visionerà senza mai pubblicarla.
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Potrai inoltre iscriverti anche alla nostra newsletter e
                        rimanere costantemente aggiornato su sconti e promozioni
                        a te riservate da parte dei nostri eShop.
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Ricorda! Teniamo a te e a tutti i nostri clienti,
                        pertanto i tuoi dati non saranno mai ceduti a terzi!
                        Anche noi, come te, detestiamo lo spam, per cui potrai
                        sempre disiscriverti in modo semplice e veloce dalla
                        nostra newsletter.
                      </span>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Come posso ricercare sul portale un negozio o un
                          prodotto?
                        </span>
                      </strong>
                    </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Puoi cercare il negozio direttamente nella Home Page di
                        eShoppingAdvisor, selezionando "eCommerce" in "cosa stai
                        cercando" e scrivendo il&nbsp;nome dell'e-commerce sulla
                        casella di ricerca.
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Qualora invece tu voglia cercare il miglior e-commerce
                        che vende un determinato prodotto o servizio, anche di
                        nicchia, seleziona "Prodotti" in "cosa stai cercando" e
                        scrivi il nome del prodotto nella casella di ricerca.
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Impostati i parametri clicca il tasto con la lente.
                      </span>
                    </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          A quali obblighi o impegni vado incontro registrandomi
                          su eShoppingAdvisor?
                        </span>
                      </strong>
                    </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Non sussiste alcun obbligo nei confronti di
                        eShoppingAdvisor o nei confronti degli&nbsp;e-commerce
                        recensiti. Le uniche regole di cui tener conto sono le
                        nostre “
                        <Link to="/it/condizioni-utilizzo">
                          condizioni di utilizzo
                        </Link>
                        ” che consideriamo imprescindibili in quanto basate
                        sulla buona educazione (netiquette) e sul buon senso.
                      </span>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Sono previsti dei costi per la registrazione?
                        </span>
                      </strong>
                    </p>
                    <p> </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        eShoppingAdvisor è gratis e lo sarà sempre. Non è
                        previsto alcun costo, a parte la tariffa internet del
                        tuo operatore telefonico!
                      </span>
                    </p>
                    <p> </p>
                    <p> </p>
                    <p>
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Per scrivere una recensione è obbligatorio
                          registrarsi?
                        </span>
                      </strong>
                    </p>
                    <p> </p>
                    <p>
                      <span style={{ fontSize: "10pt" }}>
                        Sì, per scrivere recensioni è obbligatoria la
                        registrazione. La registrazione ci aiuta, infatti, ad
                        aiutare i consumatori online come te a trovare solo
                        recensioni reali scritte da utenti reali, arginando il
                        fenomeno delle fake reviews. Gli e-commerce iscritti su
                        eShoppingAdvisor potranno inoltre così verificare
                        recensioni scritte dai loro utenti e non da detrattori
                        che utilizzano lo strumento delle recensioni in modo
                        improprio.&nbsp;&nbsp;
                      </span>{" "}
                      <span style={{ fontSize: "10pt" }}>
                        Grazie alla tua registrazione, l'e-commerce&nbsp;potrà
                        contattarti per ringraziarti&nbsp;o per risolvere
                        eventuali problematiche segnalate.&nbsp;
                      </span>{" "}
                      &nbsp;
                    </p>
                  </div>
                </Collapse>
              </div>
              <div className="well-faq-new">
                <div className="faq-title">
                  <span
                    className="title-gifts-n"
                    onClick={() => setOpen({ ...open, profilo: !open.profilo })}
                    aria-controls="item-525"
                    aria-expanded={open.profilo}
                  >
                    <img src={profiloIcon} className="ico" />
                    Profilo
                  </span>
                </div>
                <Collapse in={open.profilo}>
                  <div id="item-525" className="content-gifts-n">
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Quanti profili esistono per gli utenti recensori?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Per gli utenti esiste un unico profilo in quanto per noi
                        gli utenti sono tutti uguali. Grazie alla carriera e ai
                        riconoscimenti eShoppingAdvisor è però possibile
                        distinguersi in funzione del numero di recensioni, di
                        visualizzazioni, ecc. parametri che misurano
                        l’esperienza, la credibilità e la visibilità in rete del
                        recensore consentendogli di diventare un Consulente di
                        Riferimento all'interno di un percorso
                        riconosciuto.&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Quali funzioni ho a disposizione accedendo al mio
                          profilo?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        <Link to="/it/areariservata/login">
                          Accedendo al tuo profilo
                        </Link>
                        &nbsp;hai la possibilità di aggiornare i tuoi dati,
                        inserire un’immagine, visualizzare i riconoscimenti
                        ottenuti nel corso della tua carriera e quelli ancora da
                        conquistare. Puoi inoltre consultare l’elenco delle
                        recensioni scritte, modificarle o cancellarle,
                        consultare la tua&nbsp;
                        <Link to="/it/classifica-recensori">
                          posizione in Classifica
                        </Link>{" "}
                        tra tutti i recensori eShoppingAdvisor e sbloccare nuovi
                        coupon sconto nella sezione "Coupon".&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Posso accedere a sconti e promozioni?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Certo, ogni volta che scrivi una recensione guadagni
                        nuovi punti per sbloccare uno o più dei numerosi coupon
                        sconto messi a disposizione dagli e-commerce registrati
                        su eShoppingAdvisor.
                      </span>
                    </p>{" "}
                  </div>
                </Collapse>
              </div>
              <div className="well-faq-new">
                <div className="faq-title">
                  <span
                    className="title-gifts-n"
                    onClick={() =>
                      setOpen({ ...open, recensioni: !open.recensioni })
                    }
                    aria-controls="item-532"
                    aria-expanded={open.recensioni}
                  >
                    <img src={recensioniIcon} className="ico" />
                    Recensioni
                  </span>
                </div>
                <Collapse in={open.recensioni}>
                  <div id="item-532" className="content-gifts-n">
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          C’è un limite sul numero di recensioni da scrivere?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Non hai nessun limite sul numero di recensioni, ne
                        puoi&nbsp;
                        <Link to="/it/scrivi-recensione">scrivere</Link> quante
                        ne vuoi: scrivine sempre di più per scalare la
                        Classifica e sbloccare nuovi coupon!
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Ci sono regole da rispettare per scrivere le
                          recensioni?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Sì, le trovi riassunte tra le “
                        <Link to="/it/condizioni-di-utilizzo">
                          condizioni di utilizzo
                        </Link>
                        ”. Si tratta di semplice norme di buonsenso, correttezza
                        e bon ton. Il Team degli editor eShoppingAdvisor esamina
                        e verifica sempre tutte le recensioni fornendo un
                        preciso riscontro in pochissimo tempo in merito
                        all’opportunità di pubblicarle o meno.
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Posso scrivere recensioni anche su eshop non
                          registrati sul vostro sito?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Certo! È possibile scrivere recensioni su qualunque
                        store online, richiedendo l’aggiunta di un e-commerce
                        aiuterai altri consumatori online ad acquistare in modo
                        ancora più sicuro e consapevole premiando nel contempo
                        gli e-commerce più meritevoli. Gli e-commerce che non
                        aggiungeremo sono coloro che vendono armi, droghe, gioco
                        d'azzardo illegale e quant’altro ritenuto in qualche
                        modo offensivo o non accettabile a insindacabile
                        giudizio della redazione eShoppingAdvisor. Consulta a
                        questo proposito le "
                        <Link to="/it/condizioni-di-utilizzo">
                          condizioni di utilizzo
                        </Link>
                        ".&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Posso scrivere recensioni anche su eshop di altra
                          nazionalità o lingua?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Certamente sì! È possibile{" "}
                        <Link to="/it/scrivi-recensione">
                          scrivere recensioni{" "}
                        </Link>
                        su e-commerce di qualsiasi nazionalità o lingua,
                        conquistando in questo modo i distintivi per la Carriera
                        Geografica&nbsp;e riuscendo a diventare “Consulente
                        Mondiale” grazie, appunto, alle recensioni relative
                        a&nbsp;e-commerce presenti in tutti e cinque i
                        continenti!
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Che differenza c'è tra&nbsp;le
                          Recensioni&nbsp;Certificate e quelle Verificate?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Una recensione reca il bollino di "Recensione
                        Certificata" qualora provenga da un invito postvendita
                        da parte di un e-commerce nostro cliente. In questo caso
                        è infatti l'e-commerce registrato su eShoppingAdvisor a
                        invitare tramite email o sms l'utente recensore a
                        scrivere una recensione sulla sua esperienza di acquisto
                        e sui prodotti/servizi acquistati. Tale recensione, in
                        quanto deriva quindi da un reale acquisto di un reale
                        utente, viene da noi certificata.&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Tutte le altre recensioni possono comunque essere
                        certificate caricando una prova d'acquisto al termine
                        della recensione. Per prova d'acquisto si intende una
                        ricevuta, una fattura o una conferma d'ordine completa e
                        leggibile.&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        In ogni caso tutte le recensioni, sia certificate che
                        non certificate vengono pubblicate solo dopo opportuna
                        verifica da parte del Team preposto. Le verifiche
                        riguardano il rispetto delle condizioni di utilizzo in
                        merito all'assenza di parole e frasi censurabili in
                        quanto considerate offensive o inopportune. Particolare
                        attenzione viene posta dai nostri editor anche sulle
                        pratiche commerciali scorrette. Una pratica spesso
                        adottata è infatti quella di dare una valutazione
                        negativa all'e-commerce recensito citando poi come
                        consiglio ultimo un sito concorrente sul quale
                        effettuare un acquisto.
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Non sono infine ammesse recensioni su siti facenti parte
                        di categorie da noi considerate "non ammissibili" (es.
                        vendita prodotti e servizi illegali o "discutibili"
                        quali a titolo di mero esempio siti di gioco sprovvisti
                        di Concessione ADM - Agenzia delle Dogane e dei Monopoli
                        - , siti porno, ecc.).
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Altri controlli sono effettuati dal sistema in ordine a
                        singole incongruenze tra testo e valutazione rilasciata,
                        ecc.
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        In merito alle recensioni "non conformi" e quindi non
                        pubblicate, i nostri editor&nbsp;avranno cura di inviare
                        apposita comunicazione via email all'utente riportando
                        le motivazioni e invitandolo a effettuare le opportune
                        modifiche.
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Quanto tempo passa prima che una recensione venga
                          pubblicata?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Una recensione viene validata attraverso vari passaggi
                        che servono a verificare la conformità dei contenuti e
                        validare la prova di acquisto. Questi passaggi possono
                        richiedere fino a 24 ore.
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Potete fornirmi informazioni sull'affidabilità di un
                          e-commerce?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        In modo diretto no, in quanto non siamo in sito di
                        consulenza, ma tramite la&nbsp;
                        <Link to="/">
                          lettura delle recensioni scritte dagli utenti
                        </Link>{" "}
                        su quel determinato e-commerce è possibile verificare la
                        sua reputazione (rating) sul web e farsi un idea della
                        sua affidabilità.
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        eShoppingAdvisor è infatti una piattaforma pubblica e
                        indipendente di raccolta recensioni sulle esperienze di
                        acquisto online nel quale ogni utente della community
                        fornisce il suo contributo informativo (pubblicando,
                        appunto, la sua opinione/recensione) allo scopo di
                        aiutare gli altri utenti a fare acquisti più sicuri e
                        consapevoli.&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Potremmo infatti definirci il "TripAdvisor degli
                        e-commerce": una guida di riferimento sugli e-commerce
                        più efficienti e affidabili.&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Ho subito un disservizio/truffa da parte di un
                          e-commerce. Posso segnalarlo a voi?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Per la segnalazione di truffe o disservizi online puoi
                        certamente scrivere, in primo luogo, una recensione su
                        eShoppingAdvisor in modo da mettere in guardia altri
                        utenti e aiutarli a evitare la stessa esperienza
                        negativa.
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Prima ti consigliamo però di contattare sempre
                        l'e-commerce per&nbsp;segnalare la problematica e
                        ottenere&nbsp;la risoluzione del problema e solo qualora
                        questa non avvenga con tua piena soddisfazione potrai, a
                        seconda della gravità del caso, rivolgerti ai preposti
                        organi di controllo (es. Polizia Postale) e informare
                        altresì le Associazioni Consumatori&nbsp;per far valere
                        i tuoi diritti.&nbsp;
                      </span>
                    </p>
                    <p>
                      <span
                        id="docs-internal-guid-494ae907-7fff-c06e-9c92-b24efd2bd420"
                        style={{ fontSize: "10pt" }}
                      >
                        Ti consigliamo inoltre di consultare anche un utile
                        articolo sull'argomento scritto sul nostro{" "}
                        <Link to="https://eshoppingadvisor.com/blog/3066/il-prodotto-acquistato-on-line-e-difettoso-che-fare">
                          blog
                        </Link>
                        .&nbsp;
                      </span>
                    </p>{" "}
                  </div>
                </Collapse>
              </div>
              <div className="well-faq-new">
                <div className="faq-title">
                  <span
                    className="title-gifts-n"
                    onClick={() =>
                      setOpen({ ...open, carriera: !open.carriera })
                    }
                    aria-controls="item-534"
                    aria-expanded={open.carriera}
                  >
                    <img src={carrieraIcon} className="ico" />
                    Carriera e riconoscimenti
                  </span>
                </div>
                <Collapse in={open.carriera}>
                  <div id="item-534" className="content-gifts-n">
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Come funziona la carriera per gli utenti?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Come utente registrato eShoppingAdvisor hai accesso a
                        tre percorsi di carriera&nbsp;distinti: la prima in
                        relazione al numero di recensioni scritte (Carriera
                        Recensioni), la seconda sul numero di utenti che hanno
                        visualizzato le tue recensioni (Carriera
                        Visualizzazioni) e una terza sulle aree geografiche di
                        provenienza degli&nbsp;e-commerce&nbsp;da te recensiti
                        (Carriera Geografica). Nel tuo profilo, nella sezione
                        Riconoscimenti, avrai modo di verificare la qualifica
                        raggiunta e quanto manca per il raggiungimento della
                        prossima.
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <strong>
                        <span style={{ fontSize: "10pt" }}>
                          Come posso tenere sotto controllo la mia carriera?
                        </span>
                      </strong>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Accedendo al tuo profilo, nella sezione
                        “Riconoscimenti”, avrai modo di verificare la qualifica
                        raggiunta e quanto manca per lo step di carriera
                        successivo per ciascuno dei tre percorsi proposti
                        (Carriera Recensioni, Carriera Visualizzazioni e
                        Carriera Geografica). La tua scheda utente, che
                        comparirà in Classifica e vicino a ogni recensione
                        scritta, mostrerà ai tuoi lettori il badge della
                        Carriera Recensioni raggiunta così da certificare la tua
                        affidabilità come recensore.&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">
                      <span style={{ fontSize: "10pt" }}>
                        Entrando nella pagina "
                        <Link to="/it/classifica-recensori">
                          Classifica recensori
                        </Link>
                        "&nbsp;avrai modo di vedere la tua posizione in
                        classifica&nbsp;rispetto a tutti i recensori
                        eShoppingAdvisor. La classifica tiene conto del numero
                        di recensioni scritte e del numero di visualizzazioni
                        totali.&nbsp;
                      </span>
                    </p>
                    <p dir="ltr">&nbsp;</p>
                    <p style={{ textAlign: "justify" }}>
                      <strong>
                        Ricorda inoltre che i punti che visualizzi sul tuo
                        profilo pubblico (vetrina utente) potrai spenderli in
                        coupon sconto (1 punto = 1 coupon) all'interno della
                        sezione "Coupon" del menù del tuo profilo utente. In
                        questo modo scrivendo recensioni aiuti le persone a fare
                        acquisti online in sicurezza e nel contempo risparmi
                        durante i tuoi acquisti online.&nbsp;
                      </strong>
                    </p>{" "}
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style>{faqCss}</style>
    </section>
  )
}

export default FAQ
